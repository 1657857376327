import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ErrorMessage } from '../components/feedback/errorMessage';
import { useDictionary } from '../context/dictionary';
import { SEO } from '../components/common/seo';
import { get } from 'lodash';

export const query = graphql`
  query Error404Query {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
  }
`;

const Error404 = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const site = get(data, 'site');

  if (!settings || !site) return null;

  const heading = useDictionary("headingNotFound");
  const text = useDictionary("textNotFound");

  return (
    <>
      <SEO
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
      />
      <ErrorMessage heading={heading} text={text} />
    </>
  );
};

export default Error404;